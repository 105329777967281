

section{
    padding: 20px 0;
}

section.header#home{
    transition: height 1s;
}

section.header#home {
    height: auto;
}

section#menu{
    padding: 0;
    background-color: #415277;
}

section#menu a{
    color: white;
}

.quote#homeQuote{
    text-align: center;
    padding-bottom: 6vmin;
}
  
.quote {
    /*width: 40vw;*/
    margin: 0 auto;
}

.quote .quo{
    font-size: 6vmin;
    font-family: 'Bebas Neue', sans-serif;
}

.intro p, .intro i {
    font-weight: 500;
    font-size: larger;
}

.about p, .about i, .about h2 {
    color: white;
}

.about p, .about i {
    font-weight: 500;
    font-size: larger;  
}

.sectionBottom{
    width: 100%;
    height: 20px;
    overflow: hidden;
}

.sectionBottom.aboutBlock{
    background: #478ca7;
    background-image: linear-gradient(+2deg, #fff 49%, #0d6486 50%);
}

.expect p, .expect i {
    -font-weight: 500;
    font-size: larger;
}

.services p, .services i, .services em {
    font-weight: 500;
    font-size: larger;
}

.customIndent {
    margin-left: 55px;
}

img.photo-effect {
    border-radius: 50px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.social-links {
    
}

/*9

/* section.backbar-first {
    background: #517fe4;
} */

.room {
    padding: 20px
}
