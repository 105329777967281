.scroll{
    min-height: calc(100vh - 80px);
}

.tagline {
    padding-top: 30px;
    padding-bottom: 20px;
    font-weight: 500;
}

.b {
width: 160px;
word-wrap: normal;
}

